import { css } from '@emotion/react'
import { format, isBefore, startOfToday } from 'date-fns'
import { ComponentPropsWithoutRef, useState } from 'react'

import { RadioSwitch } from '@/components/RadioSwitch'
import { ArticleLayoutOptions } from '@/features/article-sections'
import { Calendar } from '@/features/calendar'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  events?:
    | readonly (Queries.AthleticCalendarEventFragment | null)[]
    | null
  layout: ArticleLayoutOptions
}

export const AthleticsTeamSchedule = ({
  events,
  layout,
  ...props
}: Props): JSX.Element => {
  const [showPractices, setShowPractices] = useState(true)
  const [showPastDates, setShowPastDates] = useState(false)

  const today = startOfToday()
  const eventsByMonth = events?.reduce(
    (acc, event) => {
      const date = new Date(event?.date || '')
      const isPastDate = isBefore(date, today)
      const isPractice = event?.isPractice
      if (
        (!showPractices && isPractice) ||
        (!showPastDates && isPastDate) ||
        (isPastDate && isPractice)
      ) {
        return acc
      }
      const month = format(date, 'MMMM')
      const year = format(date, 'yyyy')
      const matchingMonthYear = acc.find(
        val => val.month === month && val.year === year
      )
      if (matchingMonthYear) {
        matchingMonthYear.events.push(event)
      } else {
        acc.push({ month: month, year: year, events: [event] })
      }
      return acc
    },
    [] as {
      month: string
      year: string
      events: (Queries.AthleticCalendarEventFragment | null)[]
    }[]
  )

  const styles = {
    section: css`
      margin: 0 var(--margin) var(--row-36);
    `,
    headingArea: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: var(--gtr-36);
      margin-bottom: 1em;
    `,
    heading: css`
      flex: none;
      font-size: var(--fs-48);
      color: ${colors.gray10};
      margin: 0;
      &:after {
        content: '';
        display: block;
        width: 1em;
        margin-top: 0.5em;
        height: 2px;
        background: ${colors.red};
      }
    `,
    filters: css`
      display: flex;
      flex-wrap: wrap;
      gap: var(--gtr-36);
    `,
    filterButton: (value: boolean) => css`
      display: flex;
      flex: none;
      align-items: center;
      font-size: var(--fs-18);
      padding: 0.5em 0;
      font-weight: 350;
      transition: color 300ms ease;
      ${value === false &&
      css`
        color: ${colors.gray45};
      `}
    `,
    switch: css`
      font-size: var(--fs-21);
      margin-left: 0.333em;
    `,
  }

  return (
    <section
      css={styles.section}
      {...props}
    >
      <div css={styles.headingArea}>
        <h2 css={styles.heading}>Schedule</h2>
        <div css={styles.filters}>
          <button
            css={styles.filterButton(showPractices)}
            onClick={() => setShowPractices(prev => !prev)}
          >
            Show Practices
            <RadioSwitch
              css={styles.switch}
              value={showPractices}
            />
          </button>
          <button
            css={() => styles.filterButton(showPastDates)}
            onClick={() => setShowPastDates(prev => !prev)}
          >
            Show Past Dates
            <RadioSwitch
              css={styles.switch}
              value={showPastDates}
            />
          </button>
        </div>
      </div>
      <Calendar
        layout={layout}
        eventsByMonth={eventsByMonth}
        errorMessage={
          events?.length
            ? `No upcoming ${showPastDates ? 'or past ' : ''}${
                showPractices ? 'games or practices' : 'games'
              }.`
            : `Current schedule is not available.`
        }
      />
    </section>
  )
}
